.cuenta-name-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nombre-cuenta {
        flex: 1;
    }
}

.equivalencias-row-container {
    display: flex;
    // border-right: 1px solid rgba(92, 90, 88, 0.2);
    .column {
        display: flex;
        flex-direction: column;
        width: 120px;
        .heading {
            display: flex;
            justify-content: center;
            border-bottom: 2px solid rgb(0, 120, 212);
            font-weight: bold;
            height: 36px;
            align-items: center;
            background-color: rgb(179, 215, 242);
        }
        .content {
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: center;
            .textfield-wrapper {
                border: none;
                border-bottom: 1px solid rgb(222, 222, 222);
                border-left: 1px solid rgb(222, 222, 222);
                border-right: 1px solid rgb(222, 222, 222);
                border-radius: none;
            }
            .textfield-wrapper.focused {
                border: 2px solid rgb(0, 120, 212);
                left: 1px;
                margin-left: -2px;
            }
            .num-field {
                text-align: right;
                border-radius: none;
            }
            .bold {
                font-weight: bold;
            }
            .green {
                color: #00783e;
            }
            .red {
                color: #b3504c;
            }
        }
    }
    .column:first-child {
        flex: 1;
    }
}

.equivalencias-row-container.read-only {
    background-color: rgb(243, 243, 243);
    .num-field {
        background-color: rgb(243, 243, 243);
    }
}