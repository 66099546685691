.field-root {
    font-family: "Segoe_UI_SemiBold";
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 600;
    box-shadow: none;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    position: relative;
}

.textfield-wrapper {
    box-shadow: none;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    border: 1px solid rgb(96, 94, 92);
    left: 0;
    margin-left: 0;
    border-radius: 2px;
    background: rgb(255, 255, 255);
    cursor: text;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    position: relative;
    .number-field {
        -webkit-font-smoothing: antialiased;
        font-size: 14px;
        font-weight: 400;
        box-shadow: none;
        margin: 0px;
        padding: 0px 8px;
        box-sizing: border-box;
        border-radius: 0px;
        border: none;
        background: none transparent;
        color: rgb(50, 49, 48);
        width: 100%;
        min-width: 0px;
        text-overflow: ellipsis;
        outline: 0px;
        margin-top: 0px !important;
        &.total {
            font-weight: 600;
        }
        .label-container {
            display: flex;
            align-items: center;
        }
    }
}

.textfield-wrapper.focused {
    border: 2px solid rgb(0, 120, 212);
    left: 1px;
    margin-left: -2px;
}