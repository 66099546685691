.calculo-provisionales-modal {
    @media only screen and (min-width: 767px) {
        .ms-Dialog-main {
            width: 700px !important;
            padding-bottom: 20px;
        }
    }
    .ms-Label {
        font-family: 'Segoe_UI_Semi_Bold';
        font-weight: 600;
    }
    // h2 {
    //     font-size: 18px;
    //     font-weight: 600;
    //     padding-bottom: 4px;
    //     text-align: center;
    // }
    // h3 {
    //     font-size: 18px;
    //     font-weight: 600;
    //     padding-bottom: 4px;
    //     padding-left: 4px;
    //     border-bottom: solid 2px rgb(0, 120, 212);
    // }
    // .categories-container {
    //     display: flex;
    //     margin-top: 30px;
    //     justify-content: space-between;
    //     margin-bottom: 30px;
    //     flex-direction: column;
    //     @media only screen and (min-width: 767px) {
    //         flex-direction: row;
    //     }
    // }
    // .category-container {
    //     display: flex;
    //     flex-direction: column;
    //     margin-top: 30px;
    //     @media only screen and (min-width: 767px) {
    //         width: 48%;
    //     }
    //     .text-fields {
    //         margin-top: 20px;
    //         @media only screen and (min-width: 767px) {
    //             .num-field .ms-TextField-wrapper {
    //                 display: flex;
    //                 flex-direction: row;
    //             }
    //         }
    //         .num-field:last-child {
    //             margin-top: 14px;
    //         }
    //         .num-field label {
    //             flex: 1;
    //             padding: 8px 0px 1px;
    //             @media only screen and (min-width: 767px) {
    //                 padding: 8px 0px;
    //                 margin-right: 4px;
    //                 text-align: right;
    //             }
    //         }
    //         .num-field input {
    //             text-align: right;
    //             flex: 1;
    //         }
    //     }
    // }
    // .category-container.resumen {
    //     width: 100% !important;
    //     .text-fields {
    //         // .num-field label {
    //         //   flex: 1;
    //         //   padding: 8px 0px 1px;
    //         //   @media only screen and (min-width: 767px) {
    //         //     padding: 8px 0px;
    //         //     margin-right: 4px;
    //         //     text-align: right;
    //         //   }
    //         // }
    //         @media only screen and (min-width: 767px) {
    //             .num-field label {
    //                 flex: 0;
    //                 padding: auto 4px;
    //                 min-width: 200px;
    //                 font-size: 14px;
    //             }
    //             .num-field.small label {
    //                 font-size: 12px;
    //                 padding: 0;
    //             }
    //             .num-field input {
    //                 flex: 1;
    //             }
    //         }
    //     }
    // }
    .nav-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 40px;
    }
}

.calculo-provisionales-modal.autoSave {
    .ms-Overlay {
        background-color: transparent;
    }
    .ms-Dialog-main {
        visibility: hidden;
    }
}