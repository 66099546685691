
.info-callout {

  width: 360px;

  .callout-content {
    display: flex;
    flex-direction: column;
    padding: 16px;
    align-items: flex-start;

    p {
      font-weight: 600;

      span {
        font-weight: normal;
      }
    }

    .action-buttons {
      width: 100%;
      margin-top: 10px;
      display: flex;
      justify-content: flex-end;

      button:first-child {
        margin-right: 4px;
      }
    }
  }
}